import React from "react";
import Layout from "../components/layout/layout";
import GallerySection from "../page-sections/gallery-section/gallery-section";
import DessertSection from "../page-sections/dessert-section/dessert-section";
import AppstoreSvg from "../svg/appstore.svg";
import GooglePlaySvg from "../svg/google-play.svg";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import VerticalSpacingRow from "../components/vertical-spacing/vertical-spacing";
import * as styles from "../page-sections/contact-section/contact-section.module.scss"
import VerticalLineRow from "../components/vertical-line/vertical-line";

export default function Beerwall() {
  return <Layout>
  <Helmet>
    <meta name="apple-itunes-app" content="app-id=1563156282" />
  </Helmet>
  <VerticalSpacingRow />

<Container>
  <Row className="no-gutters align-items-center">
    <Col xs={12}>
      <div className={styles.header}>
        Pobierz aplikację BeerWall Beefteka
      </div>
    </Col>
    <VerticalSpacingRow />
      <Col xs={{offset: 4, span: 2}}>
        <a href="https://apps.apple.com/us/app/beerwall-browar-warmia/id1563156282" aria-label="App Store">
          <AppstoreSvg />
        </a>
      </Col>
      <Col xs={2}>
        <a href="https://play.google.com/store/apps/details?id=pl.lsisoftware.beerwall.browarWarmia" aria-label="Google Play">
          <GooglePlaySvg />
        </a>
      </Col>
    </Row>

</Container>
      <GallerySection />
      <DessertSection />
  </Layout>
}